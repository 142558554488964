import React, { useRef } from 'react';
import { usePageParams } from 'layout/appContext';

import { AppSEOTitle } from '../../appSEO';
import PageError, { PageErrorContent } from '../pageError';
import { MainLayout } from '../../layout/mainLayout';

import { H3, H4 } from '../../primitives/appTitle';
import AppSection from '../../primitives/appSection';
import { useAsync, UseAsyncReturn } from 'react-async-hook';
import { isDev } from '../../appEnv';
import { delayPromise } from '@shoootin/utils';
import { ShSpinner } from '@shoootin/components-web';
import { ShMediaQueries } from '@shoootin/design-tokens';
import useComponentSize from '@rehooks/component-size';

type TimelapseKeys =
  | 'novelige_saint_fons'
  | 'gcc_bobigny'
  | 'linkcity_ivry-sur-seine'
  | 'bouygues-immobilier_confluence'
  | 'brezillon-moulinet'
  | 'deskeo_brunel'
  | 'bouygues-bat-se_villa-lucia'
  | 'bouygues-bat-se_villa-lucia_blurred'
  | 'quadrilatere_meudon'
  | 'cushman-wakefield_neuilly'
  | 'leon-grosse_meyzieu'
  | 'tetris-salesforce'
  | 'quadrilatere_morland'
  | 'quadrilatere_defense'
  | 'deskeo_montmartre'
  | 'all';
type TimelapseValue = {
  src: string;
  title: string;
  rotate?: number;
};

type TimelapseValues = {
  title: string;
  values: TimelapseValue[];
};

const TimelapseValues: Record<TimelapseKeys, TimelapseValues> = {
  novelige_saint_fons: {
    title: 'Novelige - Saint Fons',
    values: [
      {
        src:
          'https://my.tikee.io/time_lapses/20334/photo_gallery/9959d893-769d-4677-8f10-9fe4f091e119?lang=fr',
        title: '',
      },
    ],
  },
  gcc_bobigny: {
    title: 'GCC - Bobigny',
    values: [
      {
        src:
          'https://my.tikee.io/time_lapses/4846/photo_gallery/5d96a96a-1517-455d-97a4-388cc283cc15?lang=fr',
        title: '',
      },
    ],
  },
  'linkcity_ivry-sur-seine': {
    title: 'Linkcity - Ivry-sur-Seine',
    values: [
      {
        src:
          'https://my.tikee.io/time_lapses/25299/photo_gallery/c958e27c-ccb2-4765-b649-f50ecfe2a9f1?lang=fr',
        title: '',
      },
    ],
  },
  'bouygues-immobilier_confluence': {
    title: 'Bouygues Immobilier - Confluence',
    values: [
      {
        src:
          'https://my.tikee.io/time_lapses/25879/photo_gallery/0b87748e-65b0-4841-acc5-b41cea366d91?lang=fr',
        title: 'A1N',
      },
      {
        src:
          'https://my.tikee.io/time_lapses/25732/photo_gallery/3fbd5dc4-5c09-40a3-ad8f-c29f3cee5665?lang=fr',
        title: 'A2N',
      },
    ],
  },
  'brezillon-moulinet': {
    title: 'Brezillon - 27 Rue du Moulinet, 75013 Paris',
    values: [
      {
        src:
          'https://my.tikee.io/time_lapses/27206/photo_gallery/19205e0c-a2e7-45c0-b806-b4aa9af85ce5?lang=fr',
        title: 'Caméra 1',
      },
      {
        src:
          'https://my.tikee.io/time_lapses/27208/photo_gallery/73da236c-842d-43c5-9ab9-18e0eca532ea?lang=fr',
        title: 'Caméra 2',
      },
    ],
  },
  deskeo_brunel: {
    title: 'Deskeo - 36 Rue Brunel, 75017 Paris',
    values: [
      {
        src:
          'https://my.tikee.io/time_lapses/16235/photo_gallery/77bbd381-af5a-416d-a815-400c5b8b25ea?lang=fr',
        title: '',
      },
    ],
  },
  'bouygues-bat-se_villa-lucia': {
    title: 'BOUYGUES BAT SE - Chantier Villa Lucia - Monaco',
    values: [
      {
        src:
          'https://my.tikee.io/time_lapses/24698/photo_gallery/00833a78-5bb7-4e76-866d-d59de24c79ac?lang=fr&allowed_viewer_types=single&hide_downloads=false',
        title: '',
        rotate: 90,
      },
    ],
  },
  'bouygues-bat-se_villa-lucia_blurred': {
    title: 'BOUYGUES BAT SE - Chantier Villa Lucia - Monaco',
    values: [
      {
        src:
          'https://my.tikee.io/time_lapses/29413/photo_gallery/77ab13a7-6558-4e1c-aaf6-6bab565c341f?lang=fr&allowed_viewer_types=single&hide_downloads=false',
        title: '',
      },
    ],
  },
  quadrilatere_meudon: {
    title: 'Quadrilatère - 12 Rue de la Verrerie, 92190 Meudon',
    values: [
      {
        src:
          'https://my.tikee.io/time_lapses/19712/photo_gallery/69cae948-3250-44e8-8469-ee2026c8778a?lang=fr',
        title: 'Caméra 1',
      },
      {
        src:
          'https://my.tikee.io/time_lapses/19723/photo_gallery/5b1f2039-2d60-4e9e-8687-e392e6924881?lang=fr',
        title: 'Caméra 2',
      },
    ],
  },
  'cushman-wakefield_neuilly': {
    title: 'Cushman & Wakefield - Neuilly-Sur Seine',
    values: [
      {
        src:
          'https://my.tikee.io/time_lapses/21455/photo_gallery/0e830e99-10d6-4565-bf05-1219c7bc4313?lang=fr',
        title: 'Caméra 1',
      },
      {
        src:
          'https://my.tikee.io/time_lapses/21286/photo_gallery/b816b6ec-9f10-4569-9386-985fe11ac608?lang=fr',
        title: 'Caméra 2',
      },
    ],
  },
  'leon-grosse_meyzieu': {
    title: 'LEON GROSSE - Meyzieu',
    values: [
      {
        src:
          'https://my.tikee.io/time_lapses/23761/photo_gallery/b9373d07-7053-4832-b56d-addde15a5432?lang=fr',
        title: '',
      },
    ],
  },
  'tetris-salesforce': {
    title: 'Tetris - Salesforce',
    values: [
      {
        src:
          'https://my.tikee.io/time_lapses/29129/photo_gallery/83efec64-9e55-4830-a10c-9e5f96d59410?lang=fr',
        title: '',
      },
      {
        src:
          'https://my.tikee.io/time_lapses/35783/photo_gallery/fe34820c-db52-471f-b570-aa45ccdc0b23?lang=fr',
        title: '',
      },
    ],
  },
  quadrilatere_morland: {
    title: 'Quadrilatère - Morland',
    values: [
      {
        src:
          'https://my.tikee.io/time_lapses/42153/photo_gallery/a9cb512f-bd39-4a34-8c29-9e21de24ec27?lang=fr',
        title: '',
      },
    ],
  },
  quadrilatere_defense: {
    title: 'Quadrilatère - La Défense',
    values: [
      {
        src:
          'https://my.tikee.io/time_lapses/42227/photo_gallery/93d47da4-c29b-47f2-8068-1df01ad098a2?lang=fr',
        title: '',
        rotate: 180,
      },
    ],
  },
  deskeo_montmartre : {
    title: 'Deskeo - Bd Montmartre',
    values: [
      {
        src:
          'https://my.tikee.io/time_lapses/48347/photo_gallery/2eaddd15-5981-42ae-83dc-8ee383a5c3d2?lang=fr',
        title: '',
      },
    ],
  },
  all: {
    title: 'Shoootin',
    values: [
      {
        src:
          'https://my.tikee.io/time_lapses/29129/photo_gallery/83efec64-9e55-4830-a10c-9e5f96d59410?lang=fr',
        title: 'Tetris - Salesforce',
      },
      {
        src:
          'https://my.tikee.io/time_lapses/35783/photo_gallery/fe34820c-db52-471f-b570-aa45ccdc0b23?lang=fr',
        title: 'Tetris - Salesforce Atrium',
      },
      {
        src:
          'https://my.tikee.io/time_lapses/23761/photo_gallery/b9373d07-7053-4832-b56d-addde15a5432?lang=fr',
        title: 'LEON GROSSE - Meyzieu',
      },
      {
        src:
          'https://my.tikee.io/time_lapses/24698/photo_gallery/00833a78-5bb7-4e76-866d-d59de24c79ac?lang=fr',
        title: 'BOUYGUES BAT SE - Chantier Villa Lucia - Monaco',
      },
      {
        src:
          'https://my.tikee.io/time_lapses/29413/photo_gallery/77ab13a7-6558-4e1c-aaf6-6bab565c341f?lang=fr',
        title: 'BOUYGUES BAT SE - Chantier Villa Lucia - Monaco - Blurred',
      },
      {
        src:
          'https://my.tikee.io/time_lapses/25879/photo_gallery/0b87748e-65b0-4841-acc5-b41cea366d91?lang=fr',
        title: 'Bouygues Immobilier - Confluence - A1N',
      },
      {
        src:
          'https://my.tikee.io/time_lapses/25732/photo_gallery/3fbd5dc4-5c09-40a3-ad8f-c29f3cee5665?lang=fr',
        title: 'Bouygues Immobilier - Confluence - A2N',
      },
      {
        src:
          'https://my.tikee.io/time_lapses/27206/photo_gallery/19205e0c-a2e7-45c0-b806-b4aa9af85ce5?lang=fr',
        title: 'Brezillon - 27 Rue du Moulinet, 75013 Paris - Caméra 1',
      },
      {
        src:
          'https://my.tikee.io/time_lapses/27208/photo_gallery/73da236c-842d-43c5-9ab9-18e0eca532ea?lang=fr',
        title: 'Brezillon - 27 Rue du Moulinet, 75013 Paris - Caméra 2',
      },
      {
        src:
          'https://my.tikee.io/time_lapses/42153/photo_gallery/a9cb512f-bd39-4a34-8c29-9e21de24ec27?lang=fr',
        title: ' Quadrilatère - Morland',
      },
      {
        src:
          'https://my.tikee.io/time_lapses/42227/photo_gallery/93d47da4-c29b-47f2-8068-1df01ad098a2?lang=fr',
        title: ' Quadrilatère - La Défense',
      },
      {
        src:
          'https://my.tikee.io/time_lapses/48347/photo_gallery/2eaddd15-5981-42ae-83dc-8ee383a5c3d2?lang=fr',
        title: 'Deskeo - Bd Montmartre',
      },
      // {
      //   src:
      //     'https://my.tikee.io/time_lapses/25299/photo_gallery/c958e27c-ccb2-4765-b649-f50ecfe2a9f1?lang=fr',
      //   title: 'Linkcity - Ivry-sur-Seine',
      // },

      // {
      //   src:
      //     'https://my.tikee.io/time_lapses/20334/photo_gallery/9959d893-769d-4677-8f10-9fe4f091e119?lang=fr',
      //   title: 'Novelige - Saint Fons',
      // },

      // {
      //   src:
      //     'https://my.tikee.io/time_lapses/16235/photo_gallery/77bbd381-af5a-416d-a815-400c5b8b25ea?lang=fr',
      //   title: 'Deskeo - 36 Rue Brunel, 75017 Paris',
      // },

      // {
      //   src:
      //     'https://my.tikee.io/time_lapses/19712/photo_gallery/69cae948-3250-44e8-8469-ee2026c8778a?lang=fr',
      //   title: 'Quadrilatère - 12 Rue de la Verrerie, 92190 Meudon - Caméra 1',
      // },
      // {
      //   src:
      //     'https://my.tikee.io/time_lapses/19723/photo_gallery/5b1f2039-2d60-4e9e-8687-e392e6924881?lang=fr',
      //   title: 'Quadrilatère - 12 Rue de la Verrerie, 92190 Meudon - Caméra 2',
      // },
      // {
      //   src:
      //     'https://my.tikee.io/time_lapses/21455/photo_gallery/0e830e99-10d6-4565-bf05-1219c7bc4313?lang=fr',
      //   title: 'Cushman & Wakefield - Neuilly-Sur Seine - Caméra 1',
      // },
      // {
      //   src:
      //     'https://my.tikee.io/time_lapses/21286/photo_gallery/b816b6ec-9f10-4569-9386-985fe11ac608?lang=fr',
      //   title: 'Cushman & Wakefield - Neuilly-Sur Seine - Caméra 2',
      // },
    ],
  },
};

const TimelapsePage = () => {
  const { timelapseId } = usePageParams();

  if (!timelapseId) {
    return <PageError />;
  }

  const asyncTimelapse: UseAsyncReturn<TimelapseValues> = useAsync(async () => {
    const timelapse = TimelapseValues[timelapseId as TimelapseKeys];
    if (timelapse) {
      if (isDev) {
        await delayPromise(2000);
      }
      return timelapse;
    } else {
      throw new Error('Incorrect url');
    }
  }, [timelapseId]);

  return (
    <div>
      {asyncTimelapse.loading && (
        <MainLayout showContact={false} className="page-login light">
          <div css={{ paddingTop: 150, paddingBottom: 150 }}>
            <ShSpinner size="s" />
          </div>
        </MainLayout>
      )}
      {asyncTimelapse.error && (
        <>
          <MainLayout className="light" showContact={false} pageName="error">
            <PageErrorContent message={asyncTimelapse.error.message} />
          </MainLayout>
        </>
      )}
      {asyncTimelapse.result && (
        <TimelapsesResult timelapses={asyncTimelapse.result} />
      )}
    </div>
  );
};

const TimelapsesResult = ({ timelapses }: { timelapses: TimelapseValues }) => {
  return (
    <MainLayout showContact={false} className="page-login light">
      <AppSEOTitle title={'Timelapse'} />
      <AppSection
        header={
          <div css={{ paddingTop: 50 }}>
            <H3>
              <span css={{ paddingRight: 20, fontSize: '70%' }}>Timelapse</span>{' '}
              {timelapses.title}
            </H3>
          </div>
        }
      >
        {timelapses.values.map((timelapse) => (
          <TimelapseResult timelapse={timelapse} key={timelapse.src} />
        ))}
      </AppSection>
    </MainLayout>
  );
};

const TimelapseResult = ({ timelapse }: { timelapse: TimelapseValue }) => {
  const ref = useRef(null);
  const { width } = useComponentSize(ref);
  const iframeSize = timelapse.rotate && width < 992 ? width : '100%';
  const childrenStyle = timelapse.rotate
    ? {
        transform: `rotate(${timelapse.rotate}deg)`,
        [ShMediaQueries.ShMobileOnly]: {
          width: iframeSize,
        },
        [ShMediaQueries.ShDesktopsOnly]: {
          width: 900,
        },
        margin: '0 auto',
      }
    : {
        width: '100%',
        marginBottom: 40,
      };

  return (
    <div ref={ref}>
      <div css={childrenStyle}>
        {timelapse.title && <H4>{timelapse.title}</H4>}
        <iframe
          src={timelapse.src}
          width={timelapse.rotate ? iframeSize : '100%'}
          height={timelapse.rotate && width < 992 ? iframeSize : 900}
          frameBorder={0}
          // css={iframeStyle}
        />
      </div>
    </div>
  );
};

export default TimelapsePage;
